<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
        <h2 class="brand-text text-primary ml-1">BEAST</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Buat Password Baru
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- Mulai membuat Password baru anda -->
          </b-card-text>


          <!-- form -->
           <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent="handleSubmit(validationForm)"
            >

              <!-- password -->
              <b-form-group
                label="Password Baru"
                label-for="reset-password-new"
              >
                 <ValidationProvider
                    name="Password Baru"
                    rules='required|min:8'
                    vid="confirmation"
                    v-slot="{ errors }"
                  >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="model.new_password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                <p class="fs-6 text-danger">
                      {{ errors[0] }}
                  </p>
                </ValidationProvider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <ValidationProvider
                  name="Konfirmasi Password"
                  rules='required|min:8|confirmed:confirmation'
                  v-slot="{ errors }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="model.password_confirmation"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                   <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                </ValidationProvider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Ubah Password
              </b-button>
            </b-form>
          </ValidationObserver>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Kembali
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import axios from "@axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config:{
        api: "/reset-password"
      },
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      model :{
        new_password:null,
        password_confirmation:null,
        email:null,
        token:null
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // console.log( this.$route.query.email );
    // console.log( this.$route.query.token );
    let a = this.$route.query.token
    let token = a.split("?") 
    if (token.length > 1) {
      this.model.token = token[1]
    }else{
      this.model.token = token[0]
    }
    this.model.email = this.$route.query.email
    
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      let _ = this
          axios
            .post(_.config.api , _.model)
            .then((response) => {
              _.model.email = null
              _.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ubah password success.",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              setTimeout(() => {
                window.location = '/login'
              }, 5000);
            })
            .catch((e) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email Tidak Ditemukan.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            });
        }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
